import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/home/page-home.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('./views/settings/page-settings.vue'),
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   component: () => import('@/views/errors/NotFound.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
