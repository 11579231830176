<script setup lang="ts">
// https://vite-plugin-pwa.netlify.app/frameworks/vue.html#periodic-sw-updates
  import { useRegisterSW } from 'virtual:pwa-register/vue'
  import { ref } from 'vue'

  const { needRefresh, updateServiceWorker } = useRegisterSW()
  const updating = ref(false)
  const hasError = ref(false)
  const btnUpdateClicked = () => {
    try {
      updating.value = true
      updateServiceWorker()
    } catch (error) {
      console.log(error)
      hasError.value = true
    }
  }
  const btnRefreshClicked = () => {
    window.location.reload()
  }
</script>

<template>
  <div
    v-if="needRefresh"
    class="bg-3 fixed bottom-0 right-0 z-50 m-4 border p-4 shadow-md dark:border-gray-500"
    role="alert">
    <div class="mb-2">
      Update tersedia
    </div>
    <template v-if="hasError">
      <div class="mb-2 w-32 text-xs text-red-400">
        Gagal melakukan update. Mohon refresh halaman dan coba lagi.
      </div>
      <div class="text-center">
        <button
          class="button"
          @click="btnRefreshClicked">
          Refresh
        </button>
      </div>
    </template>
    <div
      v-else
      class="text-center">
      <button
        class="button"
        :disabled="updating"
        :class="{'cursor-not-allowed': updating}"
        @click="btnUpdateClicked">
        <svg
          v-if="updating"
          class="h-4 w-4 animate-spin fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M222.7 32.15C227.7 49.08 218.1 66.9 201.1 71.94C121.8 95.55 64 169.1 64 255.1C64 362 149.1 447.1 256 447.1C362 447.1 448 362 448 255.1C448 169.1 390.2 95.55 310.9 71.94C293.9 66.9 284.3 49.08 289.3 32.15C294.4 15.21 312.2 5.562 329.1 10.6C434.9 42.07 512 139.1 512 255.1C512 397.4 397.4 511.1 256 511.1C114.6 511.1 0 397.4 0 255.1C0 139.1 77.15 42.07 182.9 10.6C199.8 5.562 217.6 15.21 222.7 32.15V32.15z" /></svg>
        <template v-else>
          Update
        </template>
      </button>
    </div>
  </div>
</template>

<style lang="postcss">
.button {
  @apply px-4 py-1 w-full mx-auto text-sm border dark:border-gray-500 outline-none;
  @apply bg-black bg-opacity-0 hover:bg-opacity-5;
  @apply dark:bg-white dark:bg-opacity-0 dark:hover:bg-opacity-5;
}
</style>
