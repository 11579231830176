<script setup lang="ts">
  // import AppEllipsis from '@/components/app-ellipsis.vue'
  import { useThemeStore } from '@/stores/theme'
  import FaIcon from './fa-icon.vue'

  const theme = useThemeStore()

  import { useRoute } from 'vue-router'

  const route = useRoute()
</script>

<template>
  <div>
    <div class="h-14 flex items-center gap-x-4 container">
      <div
        v-if="route.name !== 'home'"
        class="-ml-2">
        <router-link
          :to="{name: 'home'}"
          class="block px-2">
          <fa-icon
            icon="i-fas-arrow-left"
            class="size-4 text-current" />
        </router-link>
      </div>
      <h1 class="flex-1 text-xl font-bold">
        Kalkulator Urunan
      </h1>
      <div class="flex -mr-2">
        <div>
          <button
            class="block px-2"
            @click="theme.toggle">
            <fa-icon
              icon="i-fas-sun"
              class="size-4 text-current" />
          </button>
        </div>
        <div v-if="route.name !== 'settings'">
          <router-link
            class="block px-2"
            :to="{name: 'settings'}">
            <fa-icon
              icon="i-fas-gear"
              class="size-4 text-current" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
</style>
