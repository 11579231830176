<script setup lang="ts">
  import TheNavbar from '@/components/the-navbar.vue'
  import PwaUpdatePrompt from '@/components/the-pwa-update-prompt.vue'
  import { useThemeStore } from '@/stores/theme'

  const theme = useThemeStore()

  theme.setup()
</script>

<template>
  <the-navbar class="bg-3 fixed inset-x-0 top-0 z-10" />
  <div class="pt-14 container">
    <router-view v-slot="{Component}">
      <transition
        name="fade"
        mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <pwa-update-prompt />
</template>

<style lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
